const simpleWorkIdentifiedSchema = {
  type: "object",
  properties: {
    id: {
      type: "id",
      path: "WorkIdentifiedObject.id",
    },
    IdentifiedObject: {
      type: "object",
      properties: {
        id: {
          type: "id",
          path: "WorkIdentifiedObject.IdentifiedObject.id",
        },
        name: {
          type: "value",
          path: "WorkIdentifiedObject.IdentifiedObject.name",
        },
        description: {
          type: "value",
          path: "WorkIdentifiedObject.IdentifiedObject.description",
        },
      },
    },
  },
};

const workDocumentSchema = {
  type: "object",
  properties: {
    id: {
      type: "id",
      path: "WorkDocument.id",
    },
    Document: {
      type: "object",
      properties: {
        id: {
          type: "id",
          path: "WorkDocument.Document.id",
        },
        title: {
          type: "value",
          path: "WorkDocument.Document.IdentifiedObject.name",
        },
        subject: {
          type: "value",
          path: "WorkDocument.Document.IdentifiedObject.description",
        },
        status_id: {
          type: "value",
          path: "WorkDocument.Document.status.id",
        },
        IdentifiedObject: {
          type: "object",
          properties: {
            id: {
              type: "id",
              path: "WorkDocument.Document.IdentifiedObject.id",
            },
            name: {
              type: "value",
              path: "WorkDocument.Document.IdentifiedObject.name",
            },
            description: {
              type: "value",
              path: "WorkDocument.Document.IdentifiedObject.description",
            },
            enproCode: {
              type: "value",
              path: "WorkDocument.Document.IdentifiedObject.enproCode",
            },
          },
        },
        author: {
          type: "value",
          path: "WorkDocument.Document.Author.DocumentPersonRole.PersonRole.Person",
        },
        issuer: {
          type: "value",
          path: "WorkDocument.Document.Issuer.DocumentPersonRole.PersonRole.Person",
        },
        createdDateTime: {
          type: "value",
          path: "WorkDocument.Document.createdDateTime",
        },
        lastModifiedDateTime: {
          type: "value",
          path: "WorkDocument.Document.lastModifiedDateTime",
        },
      },
    },
  },
};

export const cuWorkEquipmentItemsSchema = {
  type: "object",
  properties: {
    id: {
      type: "id",
      path: "id",
    },
    WorkIdentifiedObject: { ...simpleWorkIdentifiedSchema },
    enproDuration: {
      type: "object",
      properties: {
        id: {
          type: "id",
          path: "enproDuration.id",
        },
        value: {
          type: "value",
          path: "enproDuration.value",
        },
      },
    },
    // equipCode: {
    //   type: "value",
    //   path: "equipCode",
    // },
    rate: {
      type: "object",
      properties: {
        id: {
          type: "id",
          path: "rate.id",
        },
        value: {
          type: "value",
          path: "rate.value",
        },
      },
    },
    status_id: {
      type: "value",
      path: "status.id",
    },
    enpro_c_u_work_equipment_code_id: {
      type: "value",
      path: "enproCUWorkEquipmentCode.id",
    },
    // TypeAsset
  },
};

export const cuLaborItemsSchema = {
  type: "object",
  properties: {
    id: {
      type: "id",
      path: "id",
    },
    WorkIdentifiedObject: { ...simpleWorkIdentifiedSchema },
    activityCode: {
      type: "value",
      path: "activityCode",
    },
    laborDuration: {
      type: "object",
      properties: {
        id: {
          type: "id",
          path: "laborDuration.id",
        },
        value: {
          type: "value",
          path: "laborDuration.value",
        },
      },
    },
    laborRate: {
      type: "object",
      properties: {
        id: {
          type: "id",
          path: "laborRate.id",
        },
        value: {
          type: "value",
          path: "laborRate.value",
        },
      },
    },
    status_id: {
      type: "value",
      path: "status.id",
    },
    c_u_labor_code_id: {
      type: "value",
      path: "CULaborCode.id",
    },
    QualificationRequirements: {
      type: "array",
      path: "QualificationRequirements",
      items: {
        type: "object",
        properties: {
          id: {
            type: "value",
            path: "id",
          },
        },
      },
    },
  },
};

export const cuMaterialItemsSchema = {
  type: "object",
  properties: {
    id: {
      type: "id",
      path: "id",
    },
    WorkIdentifiedObject: { ...simpleWorkIdentifiedSchema },
    corporateCode: {
      type: "value",
      path: "corporateCode",
    },
    quantity: {
      type: "object",
      properties: {
        id: {
          type: "id",
          path: "quantity.id",
        },
        value: {
          type: "value",
          path: "quantity.value",
        },
      },
    },
    status_id: {
      type: "value",
      path: "status.id",
    },
    type_material_id: {
      type: "value",
      path: "TypeMaterial.id",
    },
  },
};
export const cuContractorItemsSchema = {
  type: "object",
  properties: {
    id: {
      type: "id",
      path: "id",
    },
    WorkIdentifiedObject: { ...simpleWorkIdentifiedSchema },
    activityCode: {
      type: "value",
      path: "activityCode",
    },
    bidAmount: {
      type: "object",
      properties: {
        id: {
          type: "id",
          path: "bidAmount.id",
        },
        value: {
          type: "value",
          path: "bidAmount.value",
        },
      },
    },
    status_id: {
      type: "value",
      path: "status.id",
    },
  },
};

export const compatibleUnitSchema = {
  type: "object",
  properties: {
    WorkDocument: {
      ...workDocumentSchema,
    },
    estCost: {
      type: "object",
      properties: {
        id: {
          type: "id",
          path: "estCost.id",
        },
        value: {
          type: "value",
          path: "estCost.value",
        },
      },
    },
    enproInstruction: {
      type: "value",
      path: "enproInstruction",
    },
    quantity: {
      type: "value",
      path: "quantity",
    },
    c_u_allowable_action_id: {
      type: "value",
      path: "CUAllowableAction.id",
    },
    c_u_group_id: {
      type: "value",
      path: "CUGroup.id",
    },
    property_unit_id: {
      type: "value",
      path: "PropertyUnit.id",
    },
    cost_type_id: {
      type: "value",
      path: "CostType.id",
    },
    generic_asset_model_id: {
      type: "value",
      path: "GenericAssetModel.id",
    },
    CUWorkEquipmentItems: {
      type: "array",
      path: "CUWorkEquipmentItems",
      items: {
        ...cuWorkEquipmentItemsSchema,
      },
    },
    CULaborItems: {
      type: "array",
      path: "CULaborItems",
      items: {
        ...cuLaborItemsSchema,
      },
    },
    CUMaterialItems: {
      type: "array",
      path: "CUMaterialItems",
      items: {
        ...cuMaterialItemsSchema,
      },
    },
    CUContractorItems: {
      type: "array",
      path: "CUContractorItems",
      items: {
        ...cuContractorItemsSchema,
      },
    },
    ConditionFactors: {
      type: "array",
      path: "ConditionFactors",
      items: {
        type: "object",
        properties: {
          id: {
            type: "value",
            path: "id",
          },
        },
      },
    },
  },
};
