<template>
  <div>
    <TableSlot
      :columns="conditionFactors.columns"
      :data="value"
      :actions="true"
    >
      <template v-slot:default="{ row }">
        <td>
          {{
            lodash.get(
              lodash.find(options, {
                id: row.id,
              }),
              "WorkIdentifiedObject.IdentifiedObject.enproCode"
            )
          }}
        </td>
        <td>
          {{
            lodash.get(
              lodash.find(options, {
                id: row.id,
              }),
              "WorkIdentifiedObject.IdentifiedObject.name"
            )
          }}
        </td>
        <td>
          {{
            lodash.get(
              lodash.find(options, {
                id: row.id,
              }),
              "cfValue"
            )
          }}
        </td>
        <td>
          {{
            lodash.get(
              lodash.find(options, {
                id: row.id,
              }),
              "ConditionFactorKind.value"
            )
          }}
        </td>
      </template>
      <template v-slot:actions="{ row }">
        <UIButton
          left-icon="bi-trash"
          float
          @click="addOrRemove(row)"
          :disabled="!editable"
        />
      </template>
    </TableSlot>
    <div class="py-2 d-flex justify-content-center">
      <Modal
        title="Выбор поправочного коэффициента"
        button-text="Добавить"
        button-left-icon="bi-plus"
        :button-disabled="!editable"
      >
        <template v-slot:default>
          <TableSlot
            :columns="conditionFactors.columns"
            :data="
              options.filter((item) => !value.find((i) => i.id === item.id))
            "
            @rowClick="addOrRemove"
          />
        </template>
      </Modal>
    </div>
  </div>
</template>

<script>
import { API_conditionFactor_list } from "@/services/references/conditionFactor";
import TableSlot from "@/components/TableSlot";
import UIButton from "@/components/UI/Button";
import Modal from "@/components/UI/Modal";

export default {
  name: "CUConditionFactors",
  components: { TableSlot, UIButton, Modal },
  props: {
    value: Array,
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      options: [],
      conditionFactors: {
        columns: [
          {
            name: "Шифр",
            path: "WorkIdentifiedObject.IdentifiedObject.enproCode",
          },
          {
            name: "Наименование",
            path: "WorkIdentifiedObject.IdentifiedObject.name",
          },
          {
            name: "Значение",
            path: "cfValue",
          },
          {
            name: "Тип",
            path: "ConditionFactorKind.value",
          },
        ],
      },
    };
  },
  mounted() {
    API_conditionFactor_list().then((res) => (this.options = res));
  },
  methods: {
    addOrRemove(item) {
      if (this.value.find((i) => i.id === item.id)) {
        this.value.splice(
          this.value.findIndex((i) => i.id === item.id),
          1
        );
      } else {
        this.value.push({ id: item.id });
      }
    },
  },
};
</script>

<style scoped></style>
