var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('TableSlot',{attrs:{"columns":_vm.tablesColumns.workEquipmentsItems,"data":_vm.value,"actions":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('td',[_c('p',{staticClass:"mb-1",domProps:{"textContent":_vm._s(
            _vm.lodash.get(
              _vm.lodash.find(_vm.workEquipmentsList, {
                id: row.enpro_c_u_work_equipment_code_id,
              }),
              'WorkIdentifiedObject.IdentifiedObject.name'
            )
          )}},[_vm._v(" Название ")]),_c('p',{staticClass:"mb-0"},[_c('small',{domProps:{"textContent":_vm._s(
              _vm.lodash.get(
                _vm.lodash.find(_vm.workEquipmentsList, {
                  id: row.enpro_c_u_work_equipment_code_id,
                }),
                'WorkIdentifiedObject.IdentifiedObject.description'
              )
            )}},[_vm._v("Описание")])])]),_c('td',[_vm._v(" "+_vm._s(_vm.lodash.get( _vm.lodash.find(_vm.workEquipmentsList, { id: row.enpro_c_u_work_equipment_code_id, }), "WorkIdentifiedObject.IdentifiedObject.enproCode" ))+" ")]),_c('td',[_c('EditableInput',{attrs:{"type":"number","editable":_vm.editable,"required":"","float":""},model:{value:(row.enproDuration.value),callback:function ($$v) {_vm.$set(row.enproDuration, "value", $$v)},expression:"row.enproDuration.value"}})],1),_c('td',[_c('EditableInput',{attrs:{"editable":_vm.editable,"type":"number","required":"","float":""},model:{value:(row.rate.value),callback:function ($$v) {_vm.$set(row.rate, "value", $$v)},expression:"row.rate.value"}})],1)]}},{key:"actions",fn:function(ref){
            var row = ref.row;
return [_c('UIButton',{attrs:{"left-icon":"bi-trash","float":"","disabled":!_vm.editable},on:{"click":function($event){return _vm.addOrRemove(row)}}})]}}])}),_c('div',{staticClass:"d-flex justify-content-center py-2"},[_c('Modal',{attrs:{"title":"Машины","button-text":"Добавить","button-left-icon":"bi-plus","button-disabled":!_vm.editable},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('TableSlot',{attrs:{"columns":_vm.tablesColumns.workEquipments,"data":_vm.workEquipmentsList.filter(
              function (item) { return !_vm.value
                  .map(function (e) { return e.enpro_c_u_work_equipment_code_id; })
                  .some(function (i) { return i === item.id; }); }
            )},on:{"rowClick":_vm.addOrRemove}})]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }