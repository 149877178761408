var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('TableSlot',{attrs:{"columns":_vm.tablesColumns.materialItems,"data":_vm.value,"actions":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('td',[_vm._v(" "+_vm._s(_vm.lodash.get(row, "WorkIdentifiedObject.IdentifiedObject.name"))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.lodash.get(row, "corporateCode"))+" ")]),_c('td',[_c('EditableInput',{attrs:{"editable":_vm.editable,"required":"","float":"","type":"number"},model:{value:(row.quantity.value),callback:function ($$v) {_vm.$set(row.quantity, "value", $$v)},expression:"row.quantity.value"}})],1),_c('td',[_vm._v(" "+_vm._s(_vm.lodash.get( _vm.lodash.find(_vm.materialList, { id: row.type_material_id, }), "quantity" ))+" ")])]}},{key:"actions",fn:function(ref){
var row = ref.row;
return [_c('UIButton',{attrs:{"left-icon":"bi-trash","float":"","disabled":!_vm.editable},on:{"click":function($event){return _vm.addOrRemove(row)}}})]}}])}),_c('div',{staticClass:"d-flex justify-content-center py-2"},[_c('Modal',{attrs:{"title":"Материалы","button-text":"Добавить","button-left-icon":"bi-plus","button-disabled":!_vm.editable},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('TableSlot',{attrs:{"columns":_vm.tablesColumns.materials,"data":_vm.materialList.filter(
              function (item) { return !_vm.value
                  .map(function (e) { return e.type_material_id; })
                  .some(function (i) { return i === item.id; }); }
            )},on:{"rowClick":_vm.addOrRemove}})]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }