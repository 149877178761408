var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('TableSlot',{attrs:{"columns":_vm.tablesColumns.laborItems,"data":_vm.value,"actions":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('td',[_c('p',{staticClass:"mb-1",domProps:{"textContent":_vm._s(
            _vm.lodash.get(
              _vm.lodash.find(_vm.laborCodeList, {
                id: row.c_u_labor_code_id,
              }),
              'WorkIdentifiedObject.IdentifiedObject.name'
            )
          )}},[_vm._v(" Название ")]),_c('p',{staticClass:"mb-0"},[_c('small',{domProps:{"textContent":_vm._s(
              _vm.lodash.get(
                _vm.lodash.find(_vm.laborCodeList, {
                  id: row.c_u_labor_code_id,
                }),
                'WorkIdentifiedObject.IdentifiedObject.description'
              )
            )}},[_vm._v("Описание")])])]),_c('td',[_vm._v(" "+_vm._s(_vm.lodash.get( _vm.lodash.find(_vm.laborCodeList, { id: row.c_u_labor_code_id }), "code" ))+" ")]),_c('td',[_c('EditableInput',{attrs:{"editable":_vm.editable,"required":"","float":"","type":"number"},model:{value:(row.laborDuration.value),callback:function ($$v) {_vm.$set(row.laborDuration, "value", $$v)},expression:"row.laborDuration.value"}})],1),_c('td',[_c('EditableInput',{attrs:{"editable":_vm.editable,"type":"number","required":"","float":""},model:{value:(row.laborRate.value),callback:function ($$v) {_vm.$set(row.laborRate, "value", $$v)},expression:"row.laborRate.value"}})],1)]}},{key:"actions",fn:function(ref){
            var row = ref.row;
return [_c('UIButton',{attrs:{"left-icon":"bi-trash","float":"","disabled":!_vm.editable},on:{"click":function($event){return _vm.addOrRemove(row)}}})]}}])}),_c('div',{staticClass:"d-flex justify-content-center py-2"},[_c('Modal',{attrs:{"title":"Работы","button-text":"Добавить","button-left-icon":"bi-plus","button-disabled":!_vm.editable},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('TableSlot',{attrs:{"columns":_vm.tablesColumns.labor,"data":_vm.laborCodeList},on:{"rowClick":_vm.addOrRemove}})]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }