<template>
  <div>
    <TableSlot
      :columns="tablesColumns.contractorItems"
      :data="value"
      :actions="true"
    >
      <template v-slot:default="{ row }">
        <td>
          <EditableInput
            v-model="row.WorkIdentifiedObject.IdentifiedObject.name"
            :editable="editable"
            required
            float
          />
        </td>
        <td>
          <EditableInput
            v-model="row.activityCode"
            :editable="editable"
            required
            float
          />
        </td>
        <td>
          <EditableInput
            v-model="row.bidAmount.value"
            type="number"
            :editable="editable"
            required
            float
          />
        </td>
      </template>
      <template v-slot:actions="{ row, index }">
        <UIButton
          left-icon="bi-trash"
          float
          @click="remove(index)"
          :disabled="!editable"
        />
      </template>
    </TableSlot>
    <div class="d-flex justify-content-center py-2">
      <UIButton
        left-icon="bi-plus"
        text="Добавить"
        float
        @click="add()"
        :disabled="!editable"
      />
    </div>
  </div>
</template>

<script>
import EditableInput from "@/components/UI/EditableInput";
import TableSlot from "@/components/TableSlot";
import UIButton from "@/components/UI/Button";
import modelGenerator from "@/plugins/modelGenerator";
import { cuContractorItemsSchema } from "@/services/references/techMap/schemas";

export default {
  name: "contractorItems",
  components: { EditableInput, TableSlot, UIButton },
  props: {
    value: Array,
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tablesColumns: {
        contractorItems: [
          {
            name: "Наименование",
            path: "WorkIdentifiedObject.IdentifiedObject.name",
          },
          {
            name: "Код",
            path: "activityCode",
          },
          {
            name: "Сумма",
            path: "bidAmount.value",
          },
        ],
      },
    };
  },
  methods: {
    add() {
      this.value.push(
        modelGenerator(cuContractorItemsSchema, {
          WorkIdentifiedObject: {
            IdentifiedObject: {
              name: "",
              description: "",
            },
          },
          activityCode: "",
          bidAmount: {
            value: null,
          },
          status: {
            id: 25,
          },
        })
      );
    },
    remove(idx) {
      this.value.splice(idx, 1);
    },
  },
};
</script>

<style scoped></style>
