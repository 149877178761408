<template>
  <div class="d-inline-flex align-items-end gap-3">
    <div class="w-100">
      <dt class="col">Родительская группа:</dt>
      <dl class="col mb-0">
        <EditableInput :value="displayText" required />
      </dl>
    </div>
    <div>
      <Modal
        title="Родительский каталог"
        button-text="Изменить"
        :button-disabled="!editable"
      >
        <template v-slot:default>
          <div>
            <div class="row align-items-start">
              <div class="col">
                <TreeView
                  class="py-0"
                  :treeData="cuGroups"
                  @select-item="selectCategoryTech"
                />
              </div>
            </div>
          </div>
        </template>
      </Modal>
    </div>
  </div>
</template>

<script>
import EditableInput from "@/components/UI/EditableInput";
import Modal from "@/components/UI/Modal";
import TreeView from "@/components/TreeView/TreeView";
import { API_tech_group_list } from "@/services/references/techGroup";

export default {
  name: "cuGroupSelector",
  components: { TreeView, EditableInput, Modal },
  props: {
    value: [Number, String],
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      openModal: false,
      cuGroups: [],
    };
  },
  computed: {
    displayText: function () {
      let selected = "";

      const isInChild = (node) => {
        if (node.id === this.value) {
          selected = node.text;
        }
        if (node.children) {
          node.children.forEach((child) => isInChild(child));
        }
      };

      this.cuGroups.forEach((node) => isInChild(node));
      if (selected) {
        return selected;
      }
      return " -- ";
    },
  },
  mounted() {
    API_tech_group_list().then((res) => (this.cuGroups = res));
  },
  methods: {
    selectCategoryTech(node) {
      if (!node.children) {
        this.$emit("input", node.id);
      }
    },
  },
};
</script>

<style scoped></style>
