<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-3">
      <h2 v-text="titlePage"></h2>
      <div class="d-flex gap-3">
        <UIButton
          type="button"
          v-show="!editable"
          text="Редактировать"
          leftIcon="bi-pencil"
          :disabled="!compatibleUnit"
          @click="goEdit"
        />
        <UIButton
          type="submit"
          v-show="editable"
          text="Сохранить"
          leftIcon="bi-save"
          :disabled="!compatibleUnit"
          @click="updateButton"
          ref="update"
        />
        <UIButton
          type="submit"
          v-show="editable"
          text="Отмена"
          leftIcon="bi-save"
          :disabled="!compatibleUnit"
          @click="goToView($route.params.techMapId)"
        />
        <Modal
          v-show="editable && $attrs.mode !== 'create'"
          title="Удаление тех карты"
          button-text="Удалить"
          button-left-icon="bi-trash"
          :disabled="!compatibleUnit"
          @click="deleteButton"
          ref="remove"
          size="sm"
        >
          <template v-slot:default>
            <p>Вы уверены?</p>
          </template>
          <template v-slot:actions>
            <UIButton type="submit" text="Удалить" />
          </template>
        </Modal>
      </div>
    </div>
    <div
      class="d-flex justify-content-center align-items-center"
      style="height: 400px"
      v-if="!compatibleUnit"
    >
      <i class="spinner-border" role="status"></i>
    </div>
    <div v-if="compatibleUnit">
      <Tabs
        :headers="[
          'Основные данные',
          'Работы и машины',
          'Материалы',
          'Сторонние работы',
          'Поправочные коэффициенты',
        ]"
      >
        <template v-slot:tab-0>
          <div class="p-3 row gap-3">
            <dl class="col row gap-2">
              <div>
                <dt class="col">Наименование:</dt>
                <dl class="col">
                  <EditableInput
                    v-model="compatibleUnit.WorkDocument.Document.title"
                    :editable="editable"
                    required
                  />
                </dl>
              </div>

              <div class="d-flex gap-3">
                <div class="w-50">
                  <dt class="col">Шифр тех карты:</dt>
                  <dl class="col">
                    <EditableInput
                      v-model="
                        compatibleUnit.WorkDocument.Document.IdentifiedObject
                          .enproCode
                      "
                      :editable="editable"
                      required
                    />
                  </dl>
                </div>
                <div class="w-50">
                  <dt class="col">Допустимое действие:</dt>
                  <dl class="col">
                    <EditableSelect
                      v-model="compatibleUnit.c_u_allowable_action_id"
                      :options="actions"
                      :editable="editable"
                      required
                    />
                  </dl>
                </div>
              </div>
              <div class="d-flex gap-3">
                <div class="w-50">
                  <dt class="col">Статус:</dt>
                  <dl class="col">
                    <EditableSelect
                      v-model="compatibleUnit.WorkDocument.Document.status_id"
                      :options="statuses"
                      :editable="editable"
                      required
                    />
                  </dl>
                </div>

                <div class="w-50">
                  <dt class="col">Приоритет:</dt>
                  <dl class="col">
                    <EditableSelect
                      v-model="compatibleUnit.property_unit_id"
                      :options="priorities"
                      :editable="editable"
                      required
                    />
                  </dl>
                </div>
              </div>

              <CuGroupSelector
                v-model="compatibleUnit.c_u_group_id"
                :editable="editable"
              />

              <div class="d-flex gap-3">
                <div class="w-50">
                  <dt class="col">Единица измерения:</dt>
                  <dl class="col">
                    <EditableInput
                      v-model="compatibleUnit.quantity"
                      :editable="editable"
                      required
                    />
                  </dl>
                </div>
                <div class="w-50">
                  <dt class="col">Тип затрат:</dt>
                  <dl class="col">
                    <EditableSelect
                      v-model="compatibleUnit.cost_type_id"
                      :options="costTypes"
                      :editable="editable"
                      required
                    />
                  </dl>
                </div>
              </div>
            </dl>
            <dl class="col">
              <div class="row gap-2">
                <div>
                  <dt class="col">
                    Расчетные затраты на выполнение тех карты:
                  </dt>
                  <dl class="col">
                    {{ compatibleUnit.estCost.value || "не определено" }}
                  </dl>
                </div>
                <div>
                  <dt class="col">Описание:</dt>
                  <dl class="col">
                    <EditableInput
                      v-model="compatibleUnit.enproInstruction"
                      type="textarea"
                      :editable="editable"
                      required
                    />
                  </dl>
                </div>
              </div>
            </dl>
          </div>
        </template>
        <template v-slot:tab-1>
          <div>
            <h3 class="pt-2 ms-4">Работы</h3>
            <LaborItems
              :value="compatibleUnit.CULaborItems"
              :editable="editable"
            />
            <h3 class="ms-4">Машины</h3>
            <WorkEquipmentItems
              :value="compatibleUnit.CUWorkEquipmentItems"
              :editable="editable"
            />
          </div>
        </template>
        <template v-slot:tab-2>
          <MaterialItems
            :value="compatibleUnit.CUMaterialItems"
            :editable="editable"
          />
        </template>
        <template v-slot:tab-3>
          <ContractorItems
            :value="compatibleUnit.CUContractorItems"
            :editable="editable"
          />
        </template>
        <template v-slot:tab-4>
          <div class="pb-3">
            <ConditionFactors
              :value="compatibleUnit.ConditionFactors"
              :editable="editable"
            />
          </div>
        </template>
      </Tabs>
    </div>
  </div>
</template>

<script>
import Tabs from "@/components/UI/Tabs";
import EditableInput from "@/components/UI/EditableInput";
import EditableSelect from "@/components/UI/EditableSelect";
import UIButton from "@/components/UI/Button";
import Modal from "@/components/UI/Modal";
import CuGroupSelector from "@/views/References/technological-maps/components/cuGroupSelector";
import ConditionFactors from "@/views/References/technological-maps/components/conditionFactors";
import LaborItems from "@/views/References/technological-maps/components/laborItems";
import WorkEquipmentItems from "@/views/References/technological-maps/components/workEquipmentItems";
import MaterialItems from "@/views/References/technological-maps/components/materialItems";
import ContractorItems from "@/views/References/technological-maps/components/contractorItems";
import {
  API_compatible_unit_create,
  API_compatible_unit_delete,
  API_compatible_unit_update,
  API_compatible_unit_view,
} from "@/services/references/techMap";
import { API_CostType, API_PriorityEnum, API_StatusList } from "@/services/api";
import { API_GetAllKindsInModel } from "@/services/kind";

import { compatibleUnitSchema } from "@/services/references/techMap/schemas";
import modelGenerator from "@/plugins/modelGenerator";

export default {
  name: "compatibleUnitViewEdit",
  components: {
    Tabs,
    Modal,
    EditableInput,
    EditableSelect,
    UIButton,
    CuGroupSelector,
    ConditionFactors,
    LaborItems,
    WorkEquipmentItems,
    MaterialItems,
    ContractorItems,
  },
  data() {
    return {
      compatibleUnit: null,
      priorities: [],
      statuses: [],
      actions: [],
      costTypes: [],
      modals: {
        showSelectTreeNode: false,
        showLabor: false,
        showWork: false,
        showMaterial: false,
      },
    };
  },
  computed: {
    editable: function () {
      switch (this.$attrs.mode) {
        case "edit":
        case "create":
          return true;
        default:
          return false;
      }
    },
    titlePage: function () {
      switch (this.$attrs.mode) {
        case "edit":
          return "Редактирование технологической карты";
        case "create":
          return "Создание технологической карты";
        default:
          return "Просмотр технологической карты";
      }
    },
  },
  mounted() {
    if (this.$attrs.mode !== "create") {
      API_compatible_unit_view(this.$route.params.techMapId).then((res) => {
        this.compatibleUnit = modelGenerator(compatibleUnitSchema, res);
      });
    } else {
      this.compatibleUnit = modelGenerator(compatibleUnitSchema, {});
    }
    API_StatusList().then((res) => (this.statuses = res));
    API_PriorityEnum().then((res) => (this.priorities = res));
    API_GetAllKindsInModel("WorkActionKind").then(
      (res) => (this.actions = res)
    );
    API_CostType().then((res) => (this.costTypes = res));
  },
  methods: {
    goEdit() {
      this.$router.push({
        path: `/references/technological-maps/${this.$route.params.techMapId}/edit`,
      });
    },
    goToView(cuId) {
      window.location.href = `/references/technological-maps/${cuId}/`;
    },
    updateButton() {
      this.$refs.update.setStatus(true);
      if (this.$attrs.mode === "create") {
        API_compatible_unit_create(this.compatibleUnit)
          .then((res) => {
            this.goToView(res.id);
          })
          .finally(() => this.$refs.update.setStatus(false));
      } else {
        API_compatible_unit_update(
          this.$route.params.techMapId,
          this.compatibleUnit
        )
          .then((res) => {
            this.goToView(res.id);
          })
          .finally(() => this.$refs.update.setStatus(false));
      }
    },
    deleteButton() {
      this.$refs.remove.setStatus(true);
      API_compatible_unit_delete(this.$route.params.techMapId)
        .then(() => {
          this.$router.push({
            path: `/references/technological-maps/`,
          });
        })
        .finally(() => this.$refs.remove.setStatus(false));
    },
  },
};
</script>

<style scoped></style>
