<template>
  <div>
    <TableSlot
      :columns="tablesColumns.laborItems"
      :data="value"
      :actions="true"
    >
      <template v-slot:default="{ row }">
        <!--        <td>-->
        <!--          <div class="d-flex justify-content-between">-->
        <!--            <div class="d-flex flex-column">-->
        <!--              <span v-for="q in row.QualificationRequirements" :key="q.id">-->
        <!--                {{-->
        <!--                  lodash.get(-->
        <!--                    lodash.find(qualificationRequirementsList, { id: q.id }),-->
        <!--                    "WorkIdentifiedObject.IdentifiedObject.name",-->
        <!--                    "-&#45;&#45;"-->
        <!--                  )-->
        <!--                }}-->
        <!--              </span>-->
        <!--            </div>-->
        <!--            <Modal-->
        <!--              title="Состав звена"-->
        <!--              button-left-icon="bi-pencil"-->
        <!--              :button-disabled="!editable"-->
        <!--              size="md"-->
        <!--            >-->
        <!--              <template v-slot:default>-->
        <!--                <div>-->
        <!--                  <div-->
        <!--                    class="form-check"-->
        <!--                    v-for="item in qualificationRequirementsList"-->
        <!--                    :key="item.id"-->
        <!--                  >-->
        <!--                    <input-->
        <!--                      class="form-check-input"-->
        <!--                      type="checkbox"-->
        <!--                      :checked="-->
        <!--                        row.QualificationRequirements.find(-->
        <!--                          (i) => i.id === item.id-->
        <!--                        )-->
        <!--                      "-->
        <!--                      @input="-->
        <!--                        addOrRemoveQR(row.QualificationRequirements, item)-->
        <!--                      "-->
        <!--                      value=""-->
        <!--                      :id="idx + 'QualificationRequirements' + item.id"-->
        <!--                    />-->
        <!--                    <label-->
        <!--                      class="form-check-label"-->
        <!--                      :for="idx + 'QualificationRequirements' + item.id"-->
        <!--                    >-->
        <!--                      {{ item.WorkIdentifiedObject.IdentifiedObject.name }}-->
        <!--                    </label>-->
        <!--                  </div>-->
        <!--                </div>-->
        <!--              </template>-->
        <!--            </Modal>-->
        <!--          </div>-->
        <!--        </td>-->
        <td>
          <p
            class="mb-1"
            v-text="
              lodash.get(
                lodash.find(laborCodeList, {
                  id: row.c_u_labor_code_id,
                }),
                'WorkIdentifiedObject.IdentifiedObject.name'
              )
            "
          >
            Название
          </p>
          <p class="mb-0">
            <small
              v-text="
                lodash.get(
                  lodash.find(laborCodeList, {
                    id: row.c_u_labor_code_id,
                  }),
                  'WorkIdentifiedObject.IdentifiedObject.description'
                )
              "
              >Описание</small
            >
          </p>
        </td>
        <td>
          {{
            lodash.get(
              lodash.find(laborCodeList, { id: row.c_u_labor_code_id }),
              "code"
            )
          }}
        </td>
        <td>
          <EditableInput
            v-model="row.laborDuration.value"
            :editable="editable"
            required
            float
            type="number"
          />
        </td>
        <td>
          <EditableInput
            v-model="row.laborRate.value"
            :editable="editable"
            type="number"
            required
            float
          />
        </td>
      </template>
      <template v-slot:actions="{ row }">
        <UIButton
          left-icon="bi-trash"
          float
          @click="addOrRemove(row)"
          :disabled="!editable"
        />
      </template>
    </TableSlot>
    <div class="d-flex justify-content-center py-2">
      <Modal
        title="Работы"
        button-text="Добавить"
        button-left-icon="bi-plus"
        :button-disabled="!editable"
      >
        <template v-slot:default>
          <TableSlot
            :columns="tablesColumns.labor"
            :data="laborCodeList"
            @rowClick="addOrRemove"
          >
          </TableSlot>
        </template>
      </Modal>
    </div>
  </div>
</template>

<script>
import TableSlot from "@/components/TableSlot";
import UIButton from "@/components/UI/Button";
import Modal from "@/components/UI/Modal";
import EditableInput from "@/components/UI/EditableInput";
import { API_labor_list } from "@/services/references/labor";
import { API_qualification_list } from "@/services/references/qualification";
import { cuLaborItemsSchema } from "@/services/references/techMap/schemas";
import modelGenerator from "@/plugins/modelGenerator";

export default {
  name: "CULaborItems",
  components: { EditableInput, TableSlot, UIButton, Modal },
  props: {
    value: Array,
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tablesColumns: {
        laborItems: [
          // {
          //   name: "Состав звена",
          //   path: "QualificationRequirements",
          // },
          {
            name: "Категория ресурса",
            path: "CULaborCode.WorkIdentifiedObject.IdentifiedObject.name",
          },
          {
            name: "Код работы",
            path: "CULaborCode.code",
          },
          {
            name: "Трудозатраты на чел.",
            path: "laborDuration.value",
          },
          // {
          //   name: "Ед. и",
          //   path: "CULaborCode.enproRate.unit",
          // },
          {
            name: "Основная з/п в час",
            path: "laborRate.value",
          },
          // {
          //   name: "Ед. и",
          //   path: "CULaborCode.enproRate.unit",
          // },
          // {
          //   name: "Статус",
          //   path: "CULaborCode.status.value",
          // },
        ],
        labor: [
          {
            name: "Наименование",
            path: "WorkIdentifiedObject.IdentifiedObject.name",
          },
          {
            name: "Код",
            path: "code",
          },
          {
            name: "Тариф",
            path: "enproRate.value",
          },
          {
            name: "Статус",
            path: "status.value",
          },
        ],
        qualificationRequirements: [
          {
            name: "Квалификация",
            path: "WorkIdentifiedObject.IdentifiedObject.name",
          },
        ],
      },
      labors: [],
      qualificationRequirementsList: [],
      laborCodeList: [],
    };
  },
  created() {
    API_labor_list().then((res) => {
      this.laborCodeList = res;
    });
    API_qualification_list().then((res) => {
      this.qualificationRequirementsList = res;
    });
  },
  methods: {
    addOrRemove(item) {
      if (this.value.find((i) => i.id === item.id)) {
        this.value.splice(
          this.value.findIndex((i) => i.id === item.id),
          1
        );
      } else {
        this.value.push(
          modelGenerator(cuLaborItemsSchema, {
            WorkIdentifiedObject: {
              IdentifiedObject: {
                name: item.WorkIdentifiedObject.IdentifiedObject.name,
                description:
                  item.WorkIdentifiedObject.IdentifiedObject.description,
              },
            },
            activityCode: item.code,
            laborDuration: {
              value: null,
            },
            laborRate: {
              value: item.enproRate.value,
            },
            status: {
              id: 25,
            },
            CULaborCode: item,
            QualificationRequirements: [],
          })
        );
      }
    },
    addOrRemoveQR(row, item) {
      if (row.find((i) => i.id === item.id)) {
        row.splice(
          row.findIndex((i) => i.id === item.id),
          1
        );
      } else {
        row.push({ id: item.id });
      }
    },
  },
};
</script>

<style scoped></style>
